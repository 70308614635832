import React from 'react';
import './styles.css';
import headshot from './Headshot.png';
import Navbar from '../Components/Navbar'
import { useMediaQuery } from 'react-responsive';
import { SocialIcon } from 'react-social-icons';

function HomePage() {
const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <>
    <Navbar />
    <div className='outerContainer' >
        <div className='outerContainer2' >
        <div className='left'> 
            <div className='upperText'> 
                Hello it's me,
            </div>
            <div className='nameText'> 
                Mehul
                <span className="dotBig">.</span>
                </div>
            <div className='smallText'> 
            <ul>
                <li> Recently <a href = "https://meritpages.com/mehul" target="blank">graduated from Georgia Tech</a> with a BS in Computer Science with specializations in Artificial Intelligence and Human-Computer Interaction. </li>
                <li> Passionate about Product driven Engineering and Startups. I build stuff 👨‍💻</li>
                <li>Previously did Engineering, Product, and/or Customer stuff @ <a href = "https://www.coinbase.com/" target="blank">Coinbase</a>, <a href = "https://mercury.com/" target="blank">Mercury</a>, <a href = "https://avenue.app/" target="blank">Avenue</a>, <a href = "https://www.bizjournals.com/atlanta/news/2016/08/23/ncr-adding-innovation-lab-to-atlanta-headquarters.html" target="blank">NCR's Innovation Lab</a>, and <a href = "https://www.untapped.ventures/" target="blank">Untapped</a>. </li>
                <li> Nerding about Fintech, Consumer, Crypto, B2B SaaS, Logistics, etc. </li>
                <li> Currently Building and Scaling <a href = "https://www.palette.build/" target="blank"> Palette</a> 🎨 <a href="https://www.beondeck.com/" target="blank"> On Deck </a> Founder Fellow '21</li> 
                <li> Enjoy traveling, <a href="https://food.mehulrastogi.com/" target='blank'> dining out </a> and playing sports. </li>
                <li> Hosted a podcast - <a href="https://open.spotify.com/show/0ZVOPMPCqXx0lMVqHX6dPs" target="blank"> Capercast</a>. Certified Scuba Diver 🤿</li>
                </ul>
            </div>
        </div>
        {!isMobile && (<div >
        <img src={headshot} alt="Mehul Rastogi Headshot" />
        </div>)}
    </div>    
    <div className='icons'> 
    <SocialIcon bgColor = 'beige' fgColor='#007bff' className='socialIcons' url="https://twitter.com/mehulrastogi20" target='blank'/>
    <SocialIcon bgColor = 'beige' fgColor='#007bff' className='socialIcons' url="https://www.linkedin.com/in/mehul-rastogi-2002/" target='blank' />
    <SocialIcon bgColor = 'beige' fgColor='#007bff' className='socialIcons' url="https://github.com/Mehul20" target='blank'/>
    <SocialIcon bgColor = 'beige' fgColor='#007bff' className='socialIcons' url="https://www.instagram.com/mehul_20/" target='blank'/>
    </div>
    </div> 
    </>
  );
}

export default HomePage;
